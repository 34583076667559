import * as React from 'react';

import {ApolloClient, ApolloProvider} from '@apollo/client';

interface OneGraphProviderProps {
  client: ApolloClient<unknown>;
}

export const OneGraphProvider: React.FC<OneGraphProviderProps> = ({client, children}) => {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
