import {ApolloLink} from '@apollo/client';

export const headersLink = (customHeaders: Record<string, string> = {}): ApolloLink => (
  new ApolloLink(((operation, forward) => {
    operation.setContext(
      ({headers = {}}) => {
        const additionalHeaders: Record<string, string> = {};

        return {
          headers: {
            ...headers,
            ...additionalHeaders,
            ...customHeaders ? customHeaders : {},
          },
        };
      });

    return forward(operation);
  }))
);
