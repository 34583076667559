import {ApolloLink} from '@apollo/client';
import {RetryLink} from '@apollo/client/link/retry';

export const retryLink = (): ApolloLink => {
  return new RetryLink({
    attempts: {
      max: 3,
    },
    delay: {
      initial: 300,
    },
  });
};
