import sha256 from 'tiny-sha256';
import {ApolloLink} from '@apollo/client';
import {createPersistedQueryLink} from '@apollo/client/link/persisted-queries';

export const persistedQueriesLink = (): ApolloLink => {
  return createPersistedQueryLink({
    sha256,
    useGETForHashedQueries: true,
  });
};
