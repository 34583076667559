
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "AudiCodeCar": [
      "AudiCodeError",
      "ConfiguredCar",
      "StockCar"
    ],
    "Car": [
      "ConfiguredCar",
      "StockCar"
    ],
    "CarCode": [
      "AudiCodeErrorCarCode",
      "ConfiguredCarCarCode",
      "StockCarCarCode"
    ],
    "CarFeature": [
      "ConfigurationFeature",
      "ConfigurationPackage"
    ],
    "CarFeatureImage": [
      "ConfiguredCarFeatureImage"
    ],
    "CarFeatureText": [
      "ConfiguredCarFeatureText"
    ],
    "CarFeatureVideo": [
      "ConfiguredCarFeatureVideo"
    ],
    "CarIdentifier": [],
    "CarImages": [],
    "CarModelBase": [
      "ConfiguredCarModelBase",
      "StockCarModel"
    ],
    "CarModelIdentifier": [
      "ConfiguredCarModelIdentifier",
      "StockCarCarModelIdentifier"
    ],
    "CarPrice": [
      "ConfiguredCarCatalogModelPrice",
      "Price",
      "StockCarCarPrice"
    ],
    "CarPrices": [
      "ConfiguredCarPrices",
      "StockCarCarPrices"
    ],
    "CarlineBase": [
      "Carline",
      "ConfiguredCarCarline",
      "StockCarCarline"
    ],
    "ConfiguredCarFeatureInterface": [
      "ConfiguredCarFeature",
      "ConfiguredCarPackage"
    ],
    "ConfiguredCarMedia": [
      "ConfiguredCarFeatureImage",
      "ConfiguredCarFeatureText",
      "ConfiguredCarFeatureVideo"
    ],
    "MonthlyRateResult": [
      "FinancingError",
      "MonthlyRate"
    ],
    "OneGraphSubServiceInfo": [
      "AudicodeGraphqlInfo",
      "CarlineInfoServiceInfo",
      "ColaServiceInfo",
      "FinancingGraphqlInfo",
      "StockcarGraphqlInfo",
      "TedasServiceInfo"
    ],
    "PartRateResult": [
      "FinanceablePartRate",
      "FinancingError"
    ],
    "TechnicalData": [
      "ConfiguredCarTechnicalData"
    ],
    "TechnicalDataEngineBase": [
      "TechnicalDataElectroEngine",
      "TechnicalDataFuelEngine",
      "TechnicalDataGasEngine",
      "TechnicalDataHybridEngine"
    ]
  }
};
      export default result;
    