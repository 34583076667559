import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  InMemoryCacheConfig,
  NormalizedCacheObject,
} from '@apollo/client';

import {onegraphLink} from './link/onegraph-link';
import possibleTypes from './generated/possible-types';

export interface OnegraphClientOptions {
  endpoint: string;
  clientName: string;
  clientVersion: string;
  ssrMode?: boolean;
  customHeaders?: Record<string, string>;
  customLinks?: Array<ApolloLink>;
  disableAPQ?: boolean;
  cacheConfig?: InMemoryCacheConfig;
}

const oneGraphClients: Map<string, {
  client: ApolloClient<NormalizedCacheObject>,
  options: OnegraphClientOptions,
}> = new Map();

function getClientId(options: OnegraphClientOptions): string {
  const {clientName, clientVersion, endpoint} = options;
  return `${clientName}@${clientVersion}-${endpoint}`;
}

export function createOneGraphClient(options: OnegraphClientOptions): ApolloClient<NormalizedCacheObject> {
  const {clientName, clientVersion, ssrMode = false} = options;
  const clientId = getClientId(options);
  options.cacheConfig = {
    ...options.cacheConfig,
    ...possibleTypes,
  };

  if (!oneGraphClients.has(clientId)) {
    oneGraphClients.set(clientId, {
      options: options,
      client: new ApolloClient<NormalizedCacheObject>({
        link: onegraphLink(options),
        ssrMode,
        cache: new InMemoryCache(options.cacheConfig),
        name: clientName,
        version: clientVersion,
      }),
    });
  }

  return oneGraphClients.get(clientId)?.client;
}

function removeClient(clientId: string): void {
  if (oneGraphClients.has(clientId)) {
    const client = oneGraphClients.get(clientId);
    client.client.stop();
    client.client.cache.reset({discardWatches: true}).finally(() => {
      client.client = null;
    });
    oneGraphClients.delete(clientId);
  }
}

export function deleteOneGraphClient(options: OnegraphClientOptions): void {
  const clientId = getClientId(options);
  removeClient(clientId);
}

export function deleteOneGraphClients(): void {
  oneGraphClients.forEach((client, clientId) => {
    removeClient(clientId);
  });
}
