// Function for development. If this will be set to true APQ queries will be disabled while initializing the client
export function forceDisableAPQ(): boolean {
  if (typeof window === 'undefined' || !('sessionStorage' in window)) {
    return false;
  }

  const storageValue = window.sessionStorage.getItem('audi:disableAPQ');
  if (storageValue) {
    return typeof storageValue === 'string' && (storageValue === 'true' || storageValue === '1');
  }
  return false;
}
