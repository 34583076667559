import {ApolloLink, from} from '@apollo/client';

import {OnegraphClientOptions} from '../onegraph-client';
import {httpLink} from './http-link';
import {headersLink} from './headers-link';
import {persistedQueriesLink} from './persisted-queries-link';
import {retryLink} from './retry-link';
import {forceDisableAPQ} from '../utils/storage';

export const onegraphLink = ({customHeaders, customLinks, endpoint, disableAPQ}: OnegraphClientOptions): ApolloLink => {
  const links = [
    headersLink(customHeaders),
    retryLink(),
    httpLink(endpoint),
  ];
  if (!disableAPQ && !forceDisableAPQ()) {
    links.unshift(persistedQueriesLink());
  }

  customLinks?.forEach((link) => links.unshift(link));

  return from(links);
};
