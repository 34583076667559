import {ApolloLink, HttpLink} from '@apollo/client';
import axios from 'axios';

import {buildAxiosFetch} from './axios-fetch';

export const httpLink = (uri: string): ApolloLink => new HttpLink({
  uri,
  fetch: buildAxiosFetch(axios),
  includeExtensions: true,
});
