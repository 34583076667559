import {Request, Response, Headers} from 'node-fetch';
import {AxiosInstance, AxiosRequestConfig, Method} from 'axios';

export type AxiosFetch = (input?: string, init?: RequestInit) => Promise<Response>;

async function axiosFetch(axios: AxiosInstance, input: Request | string, init: RequestInit = {}): Promise<Response> {
  const rawHeaders: HeadersInit = init.headers || {};
  const lowerCasedHeaders = Object.keys(rawHeaders).filter((key) => key && rawHeaders[key])
    .reduce<Record<string, string>>(
      (acc, key) => {
        acc[key.toLowerCase()] = rawHeaders[key] as string;
        return acc;
      },
      {},
    );

  if (!('content-type' in lowerCasedHeaders)) {
    lowerCasedHeaders['content-type'] = 'text/plain;charset=UTF-8';
  }

  const config: AxiosRequestConfig = {
    url: input instanceof Request ? input.url : input,
    method: init.method as Method || 'GET',
    headers: lowerCasedHeaders,
    validateStatus: (): boolean => true,
  };
  if (init.body) {
    config.data = String(init.body);
  }

  const result = await axios.request(config);
  const responseBody = typeof result.data === `object` ? JSON.stringify(result.data) : result.data;

  const headers = new Headers(result.headers);

  return new Response(responseBody, {
    status: result.status,
    statusText: result.statusText,
    headers,
  });
}

export function buildAxiosFetch(axios: AxiosInstance): AxiosFetch {
  return axiosFetch.bind(undefined, axios);
}
